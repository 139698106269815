const gadgetsRotate = () => {
    if (!document.querySelector('.home-page')) return;

    const tabs = document.querySelectorAll('.earn__tab');
    const images = document.querySelectorAll('.earn__img');
    let interval;
    let index = 0;

    const imgMobile = images[0];
    const imgTablet = images[1];
    const imgDesktop = images[2];

    // функция автопереключения табов
    function autoRotate() {
        interval = setInterval(t, 2000);
    }

    function t() {
        const activeTab = tabs[index];
        const activeTabText = activeTab.getAttribute('data-device');
        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;


        tabs.forEach(tab => {
            tab.classList.remove('active');
        });

        activeTab.classList.add('active');

        // функция, очищающая инлайн стили на картинках
        function removeStyleAttribute() {
            imgMobile.removeAttribute('style');
            imgTablet.removeAttribute('style');
            imgDesktop.removeAttribute('style');
        }

        if (width < 576) {
            // устанавливаем позицию картинок
            switch (activeTabText) {
                case 'mobile':
                    removeStyleAttribute();

                    imgMobile.style.left = '50%';
                    imgMobile.style.top = '50%';
                    imgMobile.style.transform = 'translate(-50%, -50%)';

                    imgTablet.style.left = '72%';
                    imgTablet.style.bottom = '40px';
                    imgTablet.style.opacity = '0.5';
                    imgTablet.style.maxWidth = '80px';

                    imgDesktop.style.top = '0';
                    imgDesktop.style.left = '0';
                    imgDesktop.style.opacity = '0.5';
                    imgDesktop.style.maxWidth = '130px';

                    break;
                case 'tablet':
                    removeStyleAttribute();

                    imgTablet.style.left = '50%';
                    imgTablet.style.bottom = '50%';
                    imgTablet.style.transform = 'translate(-50%, 50%)';
                    imgTablet.style.maxWidth = '180px';
                    imgTablet.style.opacity = '1';

                    imgMobile.style.left = '0';
                    imgMobile.style.top = '60%';
                    imgMobile.style.bottom = 'unset';
                    imgMobile.style.opacity = '0.5';
                    imgMobile.style.maxWidth = '60px';
                    imgMobile.style.transform = 'unset';

                    imgDesktop.style.top = '10px';
                    imgDesktop.style.left = '67%';
                    imgDesktop.style.opacity = '0.5';
                    imgDesktop.style.maxWidth = '97px';

                    break;
                case 'desktop':
                    removeStyleAttribute();

                    imgDesktop.style.left = '50%';
                    imgDesktop.style.top = '50%';
                    imgDesktop.style.transform = 'translate(-50%, -50%)';
                    imgDesktop.style.maxWidth = '220px';
                    imgDesktop.style.opacity = '1';

                    imgMobile.style.left = '82%';
                    imgMobile.style.bottom = 'unset';
                    imgMobile.style.top = '58%';
                    imgMobile.style.transform = 'translateY(50%)';
                    imgMobile.style.opacity = '0.5';
                    imgMobile.style.maxWidth = '50px';

                    // imgTablet.style.top = '0';
                    imgTablet.style.bottom = '60%';
                    imgTablet.style.left = '0';
                    imgTablet.style.opacity = '0.5';
                    imgTablet.style.maxWidth = '90px';

                    break;
            }
        } else {
            // устанавливаем позицию картинок
            switch (activeTabText) {
                case 'mobile':
                    removeStyleAttribute();

                    imgMobile.style.left = '50%';
                    imgMobile.style.transform = 'translateX(-50%)';

                    // imgTablet.style.right = '50px';
                    imgTablet.style.left = '72%';
                    imgTablet.style.bottom = '40px';
                    imgTablet.style.opacity = '0.5';
                    imgTablet.style.maxWidth = '149px';

                    imgDesktop.style.top = '0';
                    imgDesktop.style.left = '0';
                    imgDesktop.style.opacity = '0.5';
                    imgDesktop.style.maxWidth = '225px';

                    break;
                case 'tablet':
                    removeStyleAttribute();

                    imgTablet.style.left = '43%';
                    imgTablet.style.transform = 'translateX(-50%)';
                    imgTablet.style.maxWidth = '338px';
                    imgTablet.style.opacity = '1';

                    imgMobile.style.left = '0';
                    imgMobile.style.bottom = '20px';
                    imgMobile.style.opacity = '0.5';
                    imgMobile.style.maxWidth = '92px';

                    imgDesktop.style.top = '0';
                    imgDesktop.style.left = '67%';
                    imgDesktop.style.opacity = '0.5';
                    imgDesktop.style.maxWidth = '190px';

                    break;
                case 'desktop':
                    removeStyleAttribute();

                    imgDesktop.style.left = '43%';
                    imgDesktop.style.transform = 'translateX(-50%)';
                    imgDesktop.style.maxWidth = '488px';
                    imgDesktop.style.opacity = '1';

                    imgMobile.style.left = '82%';
                    imgMobile.style.bottom = '50%';
                    imgMobile.style.transform = 'translateY(50%)';
                    imgMobile.style.opacity = '0.5';
                    imgMobile.style.maxWidth = '92px';

                    // imgTablet.style.top = '0';
                    imgTablet.style.bottom = '60%';
                    imgTablet.style.left = '0';
                    imgTablet.style.opacity = '0.5';
                    imgTablet.style.maxWidth = '154px';

                    break;
            }
        }

        if (width < 576) return;

        if (index === 2) {
            index = 0;
        } else {
            index++;
        }
    }

    t();

    autoRotate();

    // запуск функции по клику на табы
    tabs.forEach(tab => {
        tab.addEventListener('click', rotate);
    });

    // функция, определяющая активный таб и меняющая положение картинок
    function rotate(e) {
        const currentDeviceTab = this.getAttribute('data-device');

        // удаляем активный класс со всех табов
        tabs.forEach(tab => {
            tab.classList.remove('active');
        });

        // устанавливаем активный класс на элемент, по которому кликнули
        this.classList.add('active');

        // clear interval
        clearInterval(interval);

        // функция, очищающая инлайн стили на картинках
        function removeStyleAttribute() {
            imgMobile.removeAttribute('style');
            imgTablet.removeAttribute('style');
            imgDesktop.removeAttribute('style');
        }

        // устанавливаем позицию картинок
        switch (currentDeviceTab) {
            case 'mobile':
                removeStyleAttribute();

                imgMobile.style.left = '50%';
                imgMobile.style.transform = 'translateX(-50%)';

                // imgTablet.style.right = '50px';
                imgTablet.style.left = '72%';
                imgTablet.style.bottom = '40px';
                imgTablet.style.opacity = '0.5';
                imgTablet.style.maxWidth = '149px';

                imgDesktop.style.top = '0';
                imgDesktop.style.left = '0';
                imgDesktop.style.opacity = '0.5';
                imgDesktop.style.maxWidth = '225px';

                index = 0;
                t();
                autoRotate();

                break;
            case 'tablet':
                removeStyleAttribute();

                imgTablet.style.left = '43%';
                imgTablet.style.transform = 'translateX(-50%)';
                imgTablet.style.maxWidth = '338px';
                imgTablet.style.opacity = '1';

                imgMobile.style.left = '0';
                imgMobile.style.bottom = '20px';
                imgMobile.style.opacity = '0.5';
                imgMobile.style.maxWidth = '92px';

                imgDesktop.style.top = '0';
                imgDesktop.style.left = '67%';
                imgDesktop.style.opacity = '0.5';
                imgDesktop.style.maxWidth = '190px';

                index = 1;
                t();
                autoRotate();

                break;
            case 'desktop':
                removeStyleAttribute();

                imgDesktop.style.left = '43%';
                imgDesktop.style.transform = 'translateX(-50%)';
                imgDesktop.style.maxWidth = '488px';
                imgDesktop.style.opacity = '1';

                imgMobile.style.left = '82%';
                imgMobile.style.bottom = '50%';
                imgMobile.style.transform = 'translateY(50%)';
                imgMobile.style.opacity = '0.5';
                imgMobile.style.maxWidth = '92px';

                // imgTablet.style.top = '0';
                imgTablet.style.bottom = '60%';
                imgTablet.style.left = '0';
                imgTablet.style.opacity = '0.5';
                imgTablet.style.maxWidth = '154px';

                index = 2;
                t();
                autoRotate();

                break;
        }
    }
}

const hamburgerMenuInit = () => {
    const burger = document.querySelector('.burger-container'),
          wrapper = document.querySelector('.wrapper'),
          header = document.querySelector('.header');

    burger.addEventListener('click', function(e) {
        header.classList.toggle('menu-opened');
        document.body.classList.toggle('overflow-hidden');
        wrapper.classList.toggle('overflow-hidden');
    });
}

const openMobileSubmenu = () => {
    const menuItems = document.querySelectorAll('.main-nav .dropdown');

    menuItems.forEach(item => {
        item.addEventListener('click', function(e) {
            e.target.parentElement.classList.toggle('open');
            console.log(e.target)
            console.log(this)
        });
    });
}

const hidePreloader = () => {
    const preloaderWrapper = document.querySelector('.preloader-wrapper');

    preloaderWrapper.classList.add('hide');
}

const initStickyHeader = e => {
    const header = document.querySelector('.header');
    const scrollY = window.scrollY;

    header.classList.toggle('sticky', scrollY > 0);
    document.body.classList.toggle('scrolled', scrollY > 0);
}

const domContentLoadedHandler = () => {
    window.addEventListener('scroll', initStickyHeader);
}

const onLoadInit = () => {
    // gadgetsRotate();
    hamburgerMenuInit();
    openMobileSubmenu();
    hidePreloader();
}

const onResizeHandler = () => {
    // gadgetsRotate();
}

document.addEventListener('DOMContentLoaded', domContentLoadedHandler);
window.addEventListener('load', onLoadInit);
window.addEventListener('resize', onResizeHandler);